import React from 'react';

import logo from '../../../img/logo.png';

/**
 * Renders the main app header
 * 
 * @function Header
 * @returns {Function}
 */
const Header = () => {
  return (
    <header className="app-head">
      <img
        alt="SmarterProctoring"
        className="brand"
        src={logo}
      />
    </header>
  )
}

export default Header;
