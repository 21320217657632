/**
 * Renders a pop-up dialog.
 *
 * @property {string} content
 * @property {function} [onCancel]
 * @property {function} [onConfirm]
 * @property {function} [onTimeout]
 * @property {array} [options]
 * @property {integer} [timeout]
 * @property {string} [type]
 */

import React from "react";

// Helpers
import { focusable } from "../../helpers";

// Dependencies
import Countdown from "./Countdown";
import Select from "./Select";

const Dialog = class Dialog extends React.Component {
  constructor() {
    super();

    // Create refs
    this.dialogRoot = React.createRef();

    // Set the initial state
    this.state = {
      focusIndex: 0,
    };

    // Handle focus
    this._focusableElements = [];
  }

  componentDidMount() {
    // Dialogs must have at least one button to close them. The least
    // destructive actions should be placed first in the focus order.
    this._focusableElements =
      this.dialogRoot.current.querySelectorAll(focusable);
    this._focusableElements[0].focus();
  }

  /**
   * Focus appropriate elements
   *
   * @function focusElement
   * @param {integer} index
   */
  focusElement = (index) => {
    this.setState(
      {
        focusIndex: index,
      },
      () => {
        this._focusableElements[index].focus();
      }
    );
  };

  /**
   * Handle keyboard interaction
   *
   * @function handleKeyDown
   * @param {event} e
   */
  handleKeyDown = (e) => {
    const first = 0;
    const last = this._focusableElements.length - 1;
    const current = this.state.focusIndex;

    const ESCAPE = "Escape";
    const TAB = "Tab";
    const SHIFT = e.shiftKey;

    // Tab Key behavior
    if (e.key === TAB && !SHIFT) {
      e.preventDefault();
      if (current < last) {
        this.focusElement(current + 1);
      } else {
        this.focusElement(first);
      }
    }

    // Shift + Tab Key behavior
    if (e.key === TAB && SHIFT) {
      e.preventDefault();
      if (current > 0) {
        this.focusElement(current - 1);
      } else {
        this.focusElement(last);
      }
    }

    // Escape Key behavior
    if (e.key === ESCAPE) {
      this.handleCancel(e);
    }
  };

  /**
   * Handle dialog cancellation
   *
   * @function handleCancel
   */
  handleCancel = () => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel();
    }

    this.handleClose();
  };

  /**
   * Handle dialog confirmation
   *
   * @function handleConfirm
   */
  handleConfirm = () => {
    const { onConfirm } = this.props;

    if (onConfirm) {
      onConfirm();
    }

    this.handleClose();
  };

  /**
   * Handle dialog close
   *
   * @function handleClose
   */
  handleClose = () => {
    const { onClose, store } = this.props;
    const { AppStore } = store;

    if (onClose) {
      onClose();
    }

    AppStore.closeDialog();
  };

  /**
   * Convey the urgency of the message to assistive technology
   *
   * @function renderRole
   * @param {string} type
   * @returns {string}
   */
  renderRole = (type) => {
    let role;

    switch (type) {
      case "confirm":
      case "alert":
      case "error":
        role = "alertdialog";
        break;

      case "wide":
      case "html":
      case "info":
        role = "dialog";
        break;

      default:
        role = "dialog";
    }

    return role;
  };

  render() {
    const { content, onChange, onTimeout, options, timeout, type } = this.props;

    return (
      <div
        aria-describedby="dialog-description"
        aria-labelledby="dialog-title"
        aria-modal="true"
        className={`dialog${type ? ` dialog--${type}` : ""}`}
        id="dialog-root"
        onKeyDown={this.handleKeyDown}
        ref={this.dialogRoot}
        role={this.renderRole(type)}
      >
        <div
          className={`dialog__content${
            type ? ` dialog__content--${type}` : ""
          }`}
        >
          <div className="dialog__head">
            <h2 id="dialog-title" className="dialog__title">
              {content.heading}
            </h2>

            {timeout && (
              <Countdown
                {...this.props}
                className="dialog-countdown"
                onTimeout={onTimeout}
                timeout={timeout}
              />
            )}
          </div>

          <p id="dialog__description">{content.body}</p>

          {options && (
            <Select
              className="dialog__select"
              onChange={onChange}
              options={options}
            />
          )}

          <div className="dialog__action">
            {type === "confirm" && (
              <button className="btn btn--outline" onClick={this.handleCancel}>
                {content.cancel}
              </button>
            )}

            <button className="btn" onClick={this.handleConfirm}>
              {content.button}
            </button>
          </div>
        </div>
      </div>
    );
  }
};

export default Dialog;
