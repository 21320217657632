import React, { useEffect, useState } from 'react';

// Translation
import translate from '../translate/Translate';

// Dependencies
import { chromeBrowserCheck, getChromeVersion } from '../../lib/chrome';
import { CHROME_BROWSER_STATUS } from '../../lib/constants';

// Components
import flowCheck from '../flowCheck/FlowCheck';
import Loader from '../common/Loader';

// Icons
import * as icons from '../ui/Icons';

/**
 * Renders the browser check view
 * 
 * @function BrowserCheck
 * @param {object} props 
 * @property {Function} onSuccess
 * @returns {Function}
 */
const BrowserCheck = props => {
  // Get values from props
  const { onSuccess, translation } = props;

  // Set up state
  const [chromeStatus, setChromeStatus] = useState('');
  const [chromeVersion, setChromeVersion] = useState(0);
  const [loading, setLoading] = useState(true);

  // Main useEffect loop
  useEffect(() => {
    setChromeStatus(chromeBrowserCheck());
    setLoading(false);
  }, []);

  useEffect(() => {
    if (chromeStatus === CHROME_BROWSER_STATUS.acceptableChrome) {
      setChromeVersion(getChromeVersion());
    }
  }, [chromeStatus]);

  const renderStatus = () => {
    switch (chromeStatus) {
      case CHROME_BROWSER_STATUS.acceptableChrome:
        return (
          <div className="media">
            <img
              className="media__subject"
              height="48"
              src="/img/google_chrome_icon.png"
            />

            <div className="media__copy">
              <h6>Google Chrome ({chromeVersion})</h6>

              <p>
                <strong className="success">
                  {translation.success}
                </strong>
              </p>
            </div>
          </div>
        );

      default:
        return (
          <div className="media">
            <img
              className="media__subject"
              height="48"
              src="/img/google_chrome_icon_grey.png"
            />

            <div className="media__copy">
              <h6 className="fail">
                Google Chrome ({chromeVersion}) • {translation.fail.heading}
              </h6>

              <p>
                <strong>
                  {translation.fail.body}
                </strong>
              </p>

              <a
                className="btn btn--outline"
                href="https://www.google.com/chrome/"
                target="_blank"
                rel="noreferrer noopener"
              >
                {translation.fail.install}
              </a>
            </div>
          </div>
        );
    }
  };

  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <div className="panel panel--md panel--solo">
        {loading ? (
          <Loader isAsync={true} />
        ) : (
          <React.Fragment>
            <h1 className="panel__title" id="page-title">
              {translation.heading}
            </h1>

            {renderStatus()}

            {chromeStatus === CHROME_BROWSER_STATUS.acceptableChrome && (
              <div className="panel__action">
                <button
                  className="btn btn--icon-right"
                  onClick={() => {
                    onSuccess();
                  }}
                >
                  {translation.next}
                  <icons.chevronRight />
                </button>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </main>
  );
};

export default flowCheck('BrowserCheck')(
  translate('BrowserCheck')(BrowserCheck)
);
