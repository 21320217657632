/**
 * Selector for any HTML element that can receive keyboard focus
 *
 * @exports
 */
export const focusable =
  "a[href]:not([tabindex='-1']),area[href]:not([tabindex='-1']),\
 input:not([disabled]):not([tabindex='-1']),select:not([disabled]):not([tabindex='-1']),\
 textarea:not([disabled]):not([tabindex='-1']),button:not([disabled]):not([tabindex='-1']),\
 iframe:not([tabindex='-1']),[tabindex]:not([tabindex='-1'])";

/**
 * Convert sections to mm:ss
 *
 * @function parseSeconds
 * @param {integer} seconds
 * @returns {string}
 */
export function parseSeconds(seconds) {
  if (seconds === 0) {
    return "00:00";
  } else {
    return new Date(seconds * 1000).toISOString().substr(14, 5);
  }
}

/**
 * isDescendant - Checks if an element is a descendant of a given parent
 * @export
 * @param {parent} element
 * @param {child} element
 * @return boolean
 */
export function isDescendant(parent, child) {
  let node = child.parentNode;

  while (node !== null) {
    if (node === parent) {
      return true;
    }

    node = node.parentNode;
  }

  return false;
}
