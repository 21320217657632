import axios from "axios";
import { EXTENSION_ID_REPLACEMENT } from "./lib/constants";
import { isAppEnvLocal, isAppEnvProduction, isAppEnvStage } from "./lib/utils";
import logger from "./lib/logger";

export let config = {
  apiUrl: "https://api-stage.domain.com/v1",
  domain: "http://localhost:3001",
  extensionId: "njmamkocphdenkffaliamlfabafhmpjl",
  extensionUrl:
    "https://chrome.google.com/webstore/detail/smarterproctoring/jojmghhfemggnfdmnpihldicgpjhigja",
  extensionBaseUrl:
    "https://chrome.google.com/webstore/detail/smarterproctoring/",
  extensionId: "jojmghhfemggnfdmnpihldicgpjhigja",
  extensionManifestReplacementUrl: `https://smarterproctoring-extension.s3.amazonaws.com/manifest.${EXTENSION_ID_REPLACEMENT}.json`,
  extensionManifestUrl:
    "https://smarterproctoring-extension.s3.amazonaws.com/test/config/manifest.json",
  minChromeVersion: 74,
  launchDarklyClientSideID: "59edfdfd082f980b153ac148",
};

// Flag local development
if (isAppEnvLocal()) {
  sessionStorage.setItem("local", true);
}

// Production
if (isAppEnvProduction()) {
  config.apiUrl = "https://api.domain.com/v1";
  config.domain = "https://domain.com";
  // config.extensionId = "niffbjhghfgccacnebkhbpmgbnhocapg"; // prod-a
  config.extensionId = "jojmghhfemggnfdmnpihldicgpjhigja"; // prod-b
}

// Stage
if (isAppEnvStage()) {
  config.apiUrl = "https://api-stage.domain.com/v1";
  config.domain = "https://stage.domain.com";
}

export let api = axios.create({
  baseURL: config.apiUrl,
});

export function setToken(token) {
  api = axios.create({
    baseURL: config.apiUrl,
    headers: { token },
  });
}

logger.log("Config", config);
