/*global chrome*/
import { config } from "../config";
import axios from "axios";
import logger from "./logger";
import { isAppEnvLocal, versionCompare } from "./utils";
import { EXTENSION_ID_REPLACEMENT } from "./constants";

const useExtensionIdFromConfig = true;

/**
 * Main Utility for message passing between the app and the chrome extension
 *
 */

/**
 * Helper function do error handling on message passing
 * @param extensionId
 * @param message
 * @returns {Promise<unknown>}
 */
function sendExtensionMessage({
  extensionId = config.extensionId,
  message = "",
}) {
  return new Promise((resolve, reject) => {
    if (chrome && chrome.runtime && chrome.runtime.sendMessage) {
      const extId = useExtensionIdFromConfig ? config.extensionId : extensionId;
      logger.log(
        `Send message to extension with id ${extId} and message ${message}`
      );
      chrome.runtime.sendMessage(extId, { message }, (response) => {
        logger.log("send message ", message);
        logger.log("response", response);
        if (!response) {
          logger.error(
            "Send message error: ",
            chrome.runtime.lastError.message
          );
          reject(chrome.runtime.lastError.message);
        } else {
          resolve(response);
        }
      });
    } else {
      logger.error("Send message error: ", "No chrome runtime");
      reject("No chrome runtime");
    }
  });
}

/**
 * Specific Helper Methods
 */

/**
 * Get Extension Version Number
 * @returns {Promise<unknown|*>}
 */
export async function getExtensionVersionNumber(extensionId) {
  try {
    return await sendExtensionMessage({
      extensionId,
      message: "getVersionNumber",
    });
  } catch (e) {
    throw e;
  }
}

/**
 * Check to see if we have the latest version of the extension installed
 * @returns {Promise<boolean>}
 */
export async function isExtensionOnLatestVersion(extensionId) {
  try {
    // Get Manifest Version
    const { data } = await axios.get(
      `${config.extensionManifestReplacementUrl.replace(
        EXTENSION_ID_REPLACEMENT,
        extensionId
      )}?t=${new Date().getTime()}`
    );
    // Get current extension version
    const existingExtensionVersion = await getExtensionVersionNumber(
      extensionId
    );
    // Check to see if the versions are the same
    logger.log(
      "versionCompare(existingExtensionVersion, data.version)",
      versionCompare(existingExtensionVersion, data.version),
      existingExtensionVersion,
      data.version
    );
    return versionCompare(existingExtensionVersion, data.version) >= 0;
  } catch (e) {
    throw e;
  }
}

/**
 * Get Number of Displays attached to computer
 * @returns {Promise<unknown|*>}
 */
export async function getDisplayCount(extensionId) {
  try {
    logger.log("extensionId", extensionId);
    const { displayCount } = await sendExtensionMessage({
      extensionId,
      message: "getDisplayCount",
    });
    return displayCount;
  } catch (e) {
    throw e;
  }
}
