/**
 * Chrome browser check status
 * @type {{installChrome: string, updateChrome: string}}
 */
import { routes } from "../routes";

export const CHROME_BROWSER_STATUS = {
  installChrome: "INSTALL_CHROME",
  updateChrome: "UPDATE_CHROME",
  acceptableChrome: "ACCEPTABLE_CHROME",
};

/**
 * Names of the operating systems found in the navigator.userAgent
 * @type {{linux: string, chromebook: string, windows: string, mac: string, unknown: string}}
 */
export const OS_USER_AGENT_NAMES = {
  windows: "Windows",
  mac: "Mac",
  chromebook: "CrOS",
  linux: "Linux",
  unknown: "Unknown",
};

/**
 * Error mapping for dealing with audio and video devices
 * @type {{permissions: string, fetchDevices: string}}
 */
export const DEVICES_ERROR_MAP = {
  permissions: "PERMISSION_ERROR",
  fetchDevices: "FETCH_DEVICES_ERROR",
};

/**
 * Minimum threshold of internet speed
 * @type {{upload: int, download: int}}
 */
export const INTERNET_SPEED_THRESHOLD = {
  upload: 5120,
  download: 5120,
};

/**
 * FLOW ORDER OF SCREENS
 */

export const SYSTEM_CHECK_FLOW_ORDER = {
  Home: {
    order: 0,
    path: routes.index,
  },
  BrowserCheck: {
    order: 1,
    path: routes.browserCheck,
  },
  ExtensionCheck: {
    order: 2,
    path: routes.extensionCheck,
  },
  DisplayCheck: {
    order: 3,
    path: routes.displayCheck,
  },
  SpeedTestCheck: {
    order: 4,
    path: routes.speedTestCheck,
  },
  MicrophoneCheck: {
    order: 5,
    path: routes.microphoneCheck,
  },
  CameraCheck: {
    order: 6,
    path: routes.cameraCheck,
  },
  ScreenSharingCheck: {
    order: 7,
    path: routes.screenSharingCheck,
  },
  Done: {
    order: 8,
    path: routes.done,
  },
};

export const WINDOW_FOCUS_RELOAD_ACCEPTABLE_ROUTES = [routes.extensionCheck];

export const EXTENSION_ID_REPLACEMENT = "{{EXTENSION_ID}}";
