import React, { useEffect, useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

// Translation
import translate from '../translate/Translate';

// Helpers
import { isExtensionOnLatestVersion } from '../../lib/extension';

// Config
import { config } from '../../config';

// Components
import Loader from '../common/Loader';
import flowCheck from '../flowCheck/FlowCheck';

// Icons
import * as icons from '../ui/Icons';

/**
 * Renders the extension check view
 * 
 * @function ExtensionCheck
 * @param {object} props
 * @property {object} flags
 * @property {Function} onSuccess
 * @returns {Function}
 */
const ExtensionCheck = props => {
  // Get values from props
  const { flags, onSuccess, translation } = props;
  const extensionId = flags.extensionChromeStoreId;

  // Set up state
  const [loading, setLoading] = useState(true);
  const [onLatest, setOnLatest] = useState(false);

  // Main useEffect loop
  useEffect(() => {
    if (extensionId) {
      isExtensionOnLatestVersion(extensionId)
        .then((isLatest) => {
          setOnLatest(isLatest);
          setLoading(false);
        })
        .catch((e) => {
          setOnLatest(false);
          setLoading(false);
        });
    }
  }, [extensionId]);

  /**
   * Render the appropriate message based on whether
   * or not the extension is installed and up-to-date
   * 
   * @function renderStatus
   * @returns {Function}
   */
  const renderStatus = () => {
    if (onLatest) {
      return (
        <div className="media">
          <img
            className="media__subject"
            height="48"
            src="/img/chrome-extension-icon.png"
          />

          <div className="media__copy">
            <h6>{translation.success.heading}</h6>

            <p>
              <strong className="success">
                {translation.success.body}
              </strong>
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="video__wrap video__wrap--wide">
            <iframe
              src="https://player.vimeo.com/video/404442035?byline=0&portrait=0&autoplay=0"
              className="video__track"
              width="600"
              height="420"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            />
          </div>

          <a
            className="btn btn--full"
            href={`${config.extensionBaseUrl}${extensionId}`}
            rel="noreferrer noopener"
            target="_blank"
          >
            {translation.cta.getExtension}
          </a>
        </div>
      );
    }
  };

  let heading = translation.heading;
  let body = translation.body;

  if (!onLatest) {
    heading = translation.error.heading;
    body = translation.error.body;
  }

  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <div className="panel panel--md panel--solo">
        {loading ? (
          <Loader isAsync={true} />
        ) : (
          <div>
            <h1
              className="panel__title"
              id="page-title"
            >
              {heading}
            </h1>

            {renderStatus()}

            {onLatest && (
              <div className="panel__action">
                <button
                  className="btn btn--icon-right"
                  onClick={onSuccess}
                >
                  {translation.cta.next}
                  <icons.chevronRight />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </main>
  );
};

export default withLDConsumer()(
  flowCheck('ExtensionCheck')(translate('ExtensionCheck')(ExtensionCheck))
);
