/**
 * App router
 */
import React from "react";
import { Route, Switch } from "react-router-dom";

// Routes
import { routes } from "../routes";

// Import views
import Home from "./views/Home";
import Error404 from "./views/Error404";
import ErrorExpired from "./views/ErrorExpired";
import ErrorUnauthorized from "./views/ErrorUnauthorized";
import BrowserCheck from "./views/BrowserCheck";
import ExtensionCheck from "./views/ExtensionCheck";
import CameraCheck from "./views/CameraCheck";
import MicrophoneCheck from "./views/MicrophoneCheck";
import DisplayCheck from "./views/DisplayCheck";
import PermissionsCheck from "./views/PermissionsCheck";
import SpeedTestCheck from "./views/SpeedTestCheck";
import ScreenSharingCheck from "./views/ScreenSharingCheck";
import Done from "./views/Done";

const Router = class Router extends React.Component {
  render() {
    return (
      <Route
        render={({ location }) => (
          <Switch location={location}>
            <Route
              exact
              path={routes.index}
              render={(routing) => <Home {...routing} {...this.props} />}
            />

            <Route
              exact
              path={routes.browserCheck}
              render={(routing) => (
                <BrowserCheck {...routing} {...this.props} />
              )}
            />

            <Route
              exact
              path={routes.extensionCheck}
              render={(routing) => (
                <ExtensionCheck {...routing} {...this.props} />
              )}
            />

            <Route
              exact
              path={routes.permissionsCheck}
              render={(routing) => (
                <PermissionsCheck {...routing} {...this.props} />
              )}
            />

            <Route
              exact
              path={routes.cameraCheck}
              render={(routing) => <CameraCheck {...routing} {...this.props} />}
            />

            <Route
              exact
              path={routes.microphoneCheck}
              render={(routing) => (
                <MicrophoneCheck {...routing} {...this.props} />
              )}
            />

            <Route
              exact
              path={routes.displayCheck}
              render={(routing) => (
                <DisplayCheck {...routing} {...this.props} />
              )}
            />

            <Route
              exact
              path={routes.speedTestCheck}
              render={(routing) => (
                <SpeedTestCheck {...routing} {...this.props} />
              )}
            />

            <Route
              exact
              path={routes.screenSharingCheck}
              render={(routing) => (
                <ScreenSharingCheck {...routing} {...this.props} />
              )}
            />

            <Route
              exact
              path={routes.done}
              render={(routing) => <Done {...routing} {...this.props} />}
            />

            <Route
              path={routes.expired}
              render={(routing) => (
                <ErrorExpired {...routing} {...this.props} />
              )}
            />

            <Route
              path={routes.unauthorized}
              render={(routing) => (
                <ErrorUnauthorized {...routing} {...this.props} />
              )}
            />

            <Route
              render={(routing) => <Error404 {...routing} {...this.props} />}
            />
          </Switch>
        )}
      />
    );
  }
};

export default Router;
