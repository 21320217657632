/**
 * Renders a Loader
 */

import React from "react";

const Loader = (props) => {
  const { isAsync = false } = props;
  if (isAsync) {
    return (
      <aside className="loader--async">
        <span className="meta">Loading…</span>
      </aside>
    );
  }
  return (
    <aside className="loader">
      <div className="loader__msg">
        <span className="meta">Loading…</span>
      </div>
    </aside>
  );
};

export default Loader;
