import React, { useEffect, useState } from 'react';

// Translation
import translate from '../translate/Translate';

// Helpers
import { DEVICES_ERROR_MAP } from '../../lib/constants';
import { getDisplayCount } from '../../lib/extension';

// Dependencies
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

// Components
import Loader from '../common/Loader';
import flowCheck from '../flowCheck/FlowCheck';

// Icons
import * as icons from '../ui/Icons';

/**
 * Renders the display check view
 * 
 * @function DisplayCheck
 * @param {object} props 
 * @property {object} flags
 * @property {Function} onSuccess
 * @returns {Function}
 */
const DisplayCheck = props => {
  // Get values from props
  const { flags, onSuccess, translation } = props;
  const extensionId = flags.extensionChromeStoreId;

  // Set up state
  const [displayCount, setDisplayCount] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // Main useEffect loop
  useEffect(() => {
    checkDisplay();

    window.onbeforeunload = e => {
      e.preventDefault();
    }
  }, []);

  /**
   * Message the extension to get the number of displays
   * 
   * @function checkDisplay
   */
  const checkDisplay = () => {
    getDisplayCount(extensionId)
      .then((count) => {
        setDisplayCount(count);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(DEVICES_ERROR_MAP.permissions);
      });
  };

  const hasError =
    error === DEVICES_ERROR_MAP.permissions ||
    error === DEVICES_ERROR_MAP.fetchDevices ||
    displayCount !== 1;

  /**
   * Render the correct status based on the
   * number of displays attached
   */
  const renderStatus = () => {
    if (!hasError && displayCount === 1) {
      return (
        <div className="media">
          <div className="media__copy">
            <h6>
              {translation.displaysAttached}: {displayCount}
            </h6>

            <p>
              <strong className="success">
                {translation.success}
              </strong>
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="media">
          <div className="media__copy">
            <h6 className="fail">
              {translation.error}
            </h6>
            
            <p>
              <strong>{translation.fail}</strong>
            </p>

            <button
              className="btn btn--outline"
              onClick={checkDisplay}
            >
              {translation.checkAgain}
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <div className="panel panel--md panel--solo">
        {loading ? (
          <Loader isAsync={true} />
        ) : (
          <div>
            <h1
              className="panel__title"
              id="page-title"
            >
              {translation.heading}
            </h1>

            {renderStatus()}

            {!hasError && (
              <div className="panel__action">
                <button
                  className="btn btn--icon-right"
                  onClick={onSuccess}
                >
                  {translation.next}
                  <icons.chevronRight />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </main>
  );
};

export default withLDConsumer()(
  flowCheck('DisplayCheck')(translate('DisplayCheck')(DisplayCheck))
);
