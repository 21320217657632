import React, { useEffect, useState } from "react";

// Import Webcam
import Webcam from "react-webcam";

// Translation
import translate from "../translate/Translate";

// Dependencies
import { DEVICES_ERROR_MAP } from "../../lib/constants";
import Select from "react-select";

import {
  checkVideoStreamForData,
  getVideoStreamFromDevice,
  getWebcamsAttached,
  requestVideoPermission,
} from "../../lib/devices";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Components
import flowCheck from "../flowCheck/FlowCheck";
import logger from "../../lib/logger";

// Icons
import * as icons from '../ui/Icons';

/**
 * Renders the webcam check view
 * 
 * @function CameraCheck
 * @param {object} props
 * @property {Function} onSuccess
 * @returns {Function}
 */
const CameraCheck = props => {
  // Get values from props
  const { translation, onSuccess } = props;

  // Set up state
  const [cams, setCams] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [selectedCam, setSelectedCam] = useState(null);

  // Main useEffect loop
  useEffect(() => {
    requestVideoPermission()
      .then(() => {
        getWebcamsAttached()
          .then((devices) => {
            setCams(devices);
          })
          .catch((e) => {
            setError(DEVICES_ERROR_MAP.fetchDevices);
            logger.error(e);
          });
      })
      .catch(() => {
        setError(DEVICES_ERROR_MAP.permissions);
      });
  }, []);

  useEffect(() => {
    if (selectedCam !== "none" && selectedCam !== null) {
      getVideoStreamFromDevice(selectedCam)
        .then((stream) => {
          checkVideoStreamForData(stream)
            .then(() => {
              setSuccess(true);
              setTimeout(() => {
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
              }, 500);
            })
            .catch(() => {
              setError(true);
            });
        })
        .catch((e) => {
          setError(true);
        });
    }
  }, [selectedCam]);

  const renderStatus = () => {
    if (selectedCam && !success && error) {
      return (
        <React.Fragment>
          <p>
            <strong className="fail">
              {translation.fail}
            </strong>
          </p>

          {translation.permission && (
            <Accordion
              style={{ marginBottom: '1em', width: "100%" }}
              allowMultipleExpanded={false}
              allowZeroExpanded={true}
              key={`acc`}
            >
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={{ fontWeight: "bold" }}>
                    {translation.permission.heading}
                  </AccordionItemButton>
                </AccordionItemHeading>

                <AccordionItemPanel>
                  <ol>
                    {translation.permission.steps.map((step, i) => (
                      <li key={`step-${i}`}>
                        <p>{step.description}</p>

                        {step.image && (
                          <img
                            alt={step.description}
                            height="300"
                            src={`/img/permissions/${step.image}`}
                          />
                        )}
                      </li>
                    ))}
                  </ol>

                  {translation.permission &&
                    translation.permission.extraLink && (
                      <div>
                        <span>{translation.permission.extraLink.prefix}</span>
                        <a
                          href={translation.permission.extraLink.link}
                          target={"_blank"}
                        >
                          {translation.permission.extraLink.linkText}
                        </a>
                        <span>{translation.permission.extraLink.suffix}</span>
                      </div>
                    )}
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          )}
          
          {translation.permission && translation.permission.system && (
            <React.Fragment>
              <h6 className="panel__subtitle">
                {translation.permission.system.heading}
              </h6>

              <p>{translation.permission.system.description}</p>

              {translation.permission.system.extraLink && (
                <div>
                  <span>{translation.permission.system.extraLink.prefix}</span>

                  <a
                    href={translation.permission.system.extraLink.link}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {translation.permission.system.extraLink.linkText}
                  </a>

                  <span>{translation.permission.system.extraLink.suffix}</span>
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }

    if (selectedCam && success) {
      return (
        <div className="row">
          <div className={""}>
            <div className={""}>
              <p className={"text__success text__bold"}>
                {translation.success}
              </p>
            </div>
            <Webcam width={"100%"} />
          </div>
        </div>
      );
    }
  };

  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <div className="panel panel--md panel--solo">
        <h1 className="panel__title" id="page-title">
          {translation.heading}
        </h1>
        <p>{translation.body}</p>
        <br />
        <h6>{translation.attachedCams}</h6>
        <br />
        <Select
          isDisabled={success}
          onChange={({ value }) => {
            setSelectedCam(value);
          }}
          defaultValue={{ value: "none", label: "None" }}
          options={[
            { value: "none", label: "None" },
            ...cams.map((cam) => {
              return { value: cam.deviceId, label: cam.label };
            }),
          ]}
        />
        <div style={{ marginTop: "30px" }}>{renderStatus()}</div>

        {success && (
          <div className="panel__action">
            <button
              className="btn btn--icon-right"
              onClick={() => {
                onSuccess();
              }}
            >
              {translation.next}
              <icons.chevronRight />
            </button>
          </div>
        )}
      </div>
    </main>
  );
};

export default flowCheck("CameraCheck")(translate("CameraCheck")(CameraCheck));
