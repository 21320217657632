/**
 * Renders a Select component
 *
 * @property {string} [className]
 * @property {boolean} [defaultValue]
 * @property {boolean} [disabled]
 * @property {boolean} [hideLabel]
 * @property {string} id
 * @property {string} label
 * @property {function} onChange
 * @property {array} options
 * @property {string} placeholder
 * @property {boolean} [required]
 */

 import React from "react";

 const Select = class Select extends React.Component {
   render() {
     const {
       className,
       defaultValue,
       disabled,
       hideLabel,
       id,
       label,
       onChange,
       options,
       placeholder,
       required,
     } = this.props;
 
     return (
       <div className="select-wrap">
         <label className={hideLabel ? "meta" : "form__lbl select__lbl"} htmlFor={id}>
           {label}
         </label>
 
         <div className={`select${className ? ` ${className}` : ""}`}>
           <select
             aria-required={required}
             defaultValue={defaultValue || ""}
             disabled={disabled}
             id={id}
             onChange={(e) => onChange(e.target.value)}
             required={required}
           >
             <option value="">{placeholder || "Choose an option…"}</option>
 
             {options &&
               options.length > 0 &&
               options.map((option, i) => (
                 <option key={i} value={option.value}>
                   {option.label}
                 </option>
               ))}
           </select>
         </div>
       </div>
     );
   }
 };
 
 export default Select;
 