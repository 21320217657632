/**
 * Renders a countdown
 * @prop {integer} timeout - The countdown length in seconds
 * @prop {string} [className] - Optional classes for CSS & styles
 * @prop {function} [onTimeout] - Callback to run after countdown reaches 0
 */

import React from "react";

// Helpers
import { parseSeconds } from "../../helpers";

const Countdown = class Countdown extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      timeout: null,
    };
  }

  componentDidMount() {
    const { timeout } = this.props;

    // Set the countdown at its beginning value
    this.setState({
      timeout,
    });

    this.timer = setInterval(this.handleCountdown, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  /**
   * Count the time down.
   *
   * @function handleCountdown
   */
  handleCountdown = () => {
    const { onTimeout } = this.props;
    let { timeout } = this.state;

    // Subtract 1 second
    // Note: The timeout variable is a representation
    // of seconds rather than milliseconds primarily
    // because the API tends to return things in seconds
    timeout -= 1;

    this.setState({
      timeout,
    });

    if (timeout <= 0) {
      clearInterval(this.timer);

      if (onTimeout) {
        onTimeout();
      }
    }
  };

  render() {
    const { className } = this.props;
    const { timeout } = this.state;

    return (
      <div
        id="timer-root"
        role="timer"
        aria-live="off"
        className={`countdown${className ? ` ${className}` : ""}`}
      >
        {(timeout || timeout === 0) && parseSeconds(timeout)}
      </div>
    );
  }
};

export default Countdown;
