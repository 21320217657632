import React, { useEffect } from "react";

// Translation
import translate from "../translate/Translate";

const Done = (props) => {
  const { translation, store } = props;
  const { AppStore } = store;

  useEffect(() => {
    AppStore.clearNextCheckFlowIndex();
  }, []);

  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <div className="panel panel--md panel--solo">
        <h1 className="panel__title" id="page-title">
          {translation.heading}
        </h1>
        <div className={"text__center"} style={{ marginBottom: "24px" }}>
          <img height={"64px"} src={"/img/success-green-check-mark.png"} />
        </div>
        <p>{translation.body}</p>
      </div>
    </main>
  );
};

export default translate("Done")(Done);
