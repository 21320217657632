import React from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';

// Config
import { config } from '../config';

// Dependencies
import { withLDProvider } from 'launchdarkly-react-client-sdk';

// Import common components
import Dialog from './common/Dialog';
import Header from './common/Header';
import Loader from './common/Loader';
import Router from './Router';
import Toast from './common/Toast';

/**
 * Renders the main app container
 * 
 * @function App
 * @param {object} props
 * @returns {Function}
 */
const App = props => {
  // Get values from props
  const { store } = props;
  const { AppStore } = store;

  // Set up state
  const [render, setRender] = React.useState(true);

  // Main useEffect loop
  React.useEffect(() => {
    if (!AppStore.translation) {
      AppStore.getTranslation();
    }
  }, []);

  const renderZDChat = () => {
    const zdScript = document.querySelector('#ze-snippet');

    if(!zdScript) {
      let zdc = document.createElement('script');
      zdc.id = 'ze-snippet';
      zdc.type = 'text/javascript';
      zdc.async = true;
      zdc.src = 'https://static.zdassets.com/ekr/snippet.js?key=a3206868-bdac-4af5-a921-ee51087a581e';

      zdc.onload = function() {
        window.zE(function(){
          window.zE('webWidget', 'updateSettings', {
            webWidget: {
              offset: {
                vertical: '44px'
              }
            }
          });
        });
      }

      document.body.appendChild(zdc);
    }

    return null;
  }

  if (render) {
    return (
      <BrowserRouter>
        <div className="viewport">
          <Header />

          <Router {...props} />

          {AppStore.dialog && (
            <Dialog
              {...props}
              content={AppStore.dialogContent}
              onCancel={AppStore.dialogOnCancel}
              onChange={AppStore.dialogOnChange}
              onConfirm={AppStore.dialogOnConfirm}
              onTimeout={AppStore.dialogOnTimeout}
              options={AppStore.dialogOptions}
              timeout={AppStore.dialogTimeout}
              type={AppStore.dialogType}
            />
          )}

          {AppStore.toast && (
            <Toast
              {...props}
              content={AppStore.toast}
              onDismiss={AppStore.toastOnDismiss}
              timeout={AppStore.toastTimeout}
              type={AppStore.toastType}
            />
          )}

          {renderZDChat()}

          {AppStore.loading && <Loader />}
        </div>
      </BrowserRouter>
    );
  }
  
  return null;
};

export default observer(
  withLDProvider({
    clientSideID: config.launchDarklyClientSideID,
  })(App)
);
