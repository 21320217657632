import React, { useEffect, useState } from "react";
import Select from "react-select";

// Translation
import translate from "../translate/Translate";

// Dependencies
import { DEVICES_ERROR_MAP } from "../../lib/constants";
import {
  checkAudioStreamForData,
  createAudioMeter,
  getAudioStreamFromDevice,
  getMicrophonesAttached,
  requestAudioPermission,
} from "../../lib/devices";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Components
import flowCheck from "../flowCheck/FlowCheck";

// Icons
import * as icons from '../ui/Icons';

let mediaStreamSource = null;
let audioContext = null;
let meter = null;
let canvasContext = null;
let rafID = null;

function setupMicAnimationFromStream(stream) {
  canvasContext = document.getElementById("meter").getContext("2d");
  audioContext = new AudioContext();
  // Create an AudioNode from the stream.
  mediaStreamSource = audioContext.createMediaStreamSource(stream);

  // Create a new volume meter and connect it.
  meter = createAudioMeter(audioContext);
  mediaStreamSource.connect(meter);

  // kick off the visual updating
  drawMicAnimation(canvasContext);
}

function drawMicAnimation(canvas) {
  // clear the background
  canvasContext.clearRect(0, 0, 300, 320);

  canvasContext.fillStyle = "#0074cc";

  // draw a bar based on the current volume
  canvasContext.fillRect(0, 0, 400, meter.volume * 320);

  // set up the next visual callback
  rafID = window.requestAnimationFrame(drawMicAnimation);
}

/**
 * Renders the microphone check view
 * 
 * @function MicrophoneCheck
 * @param {object} props 
 * @property {Function} onSuccess
 * @returns {Function}
 */
const MicrophoneCheck = (props) => {
  // Get values from props
  const { translation, onSuccess } = props;

  // Set up state
  const [mics, setMics] = useState([]);
  const [error, setError] = useState(null);
  const [selectedMic, setSelectedMic] = useState(null);
  const [success, setSuccess] = useState(false);

  // Main useEffect loop
  useEffect(() => {
    requestAudioPermission()
      .then((stream) => {
        getMicrophonesAttached()
          .then((devices) => {
            setMics(devices);
          })
          .catch((e) => {
            setError(DEVICES_ERROR_MAP.fetchDevices);
          });
      })
      .catch(() => {
        setError(DEVICES_ERROR_MAP.permissions);
      });
  }, []);

  useEffect(() => {
    if (selectedMic !== "none" && selectedMic !== null) {
      getAudioStreamFromDevice(selectedMic)
        .then((stream) => {
          setupMicAnimationFromStream(stream);
          checkAudioStreamForData(stream)
            .then(() => {
              setSuccess(true);
              setTimeout(() => {
                const tracks = stream.getTracks();
                tracks.forEach((track) => track.stop());
              }, 500);
            })
            .catch(() => {
              setError(true);
            });
        })
        .catch((e) => {
          setError(true);
        });
    } else {
      window.cancelAnimationFrame(rafID);
    }
  }, [selectedMic]);

  const renderStatus = () => {
    if (selectedMic && !success && error) {
      return (
        <React.Fragment>
          <p>
            <strong className="fail">
              {translation.fail}
            </strong>
          </p>

          {translation.permission && (
            <Accordion
              style={{ width: "100%" }}
              allowMultipleExpanded={false}
              allowZeroExpanded={true}
              key={`acc`}
            >
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton style={{ fontWeight: "bold" }}>
                    {translation.permission.heading}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ol>
                    {translation.permission.steps.map((step, i) => (
                      <li key={`step-${i}`}>
                        <p>{step.description}</p>

                        {step.image && (
                          <img
                            alt={step.description}
                            height="300"
                            src={`/img/permissions/${step.image}`}
                          />
                        )}
                      </li>
                    ))}
                  </ol>
                  {translation.permission &&
                    translation.permission.extraLink && (
                      <div>
                        <span>{translation.permission.extraLink.prefix}</span>
                        <a
                          href={translation.permission.extraLink.link}
                          target={"_blank"}
                        >
                          {translation.permission.extraLink.linkText}
                        </a>
                        <span>{translation.permission.extraLink.suffix}</span>
                      </div>
                    )}
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          )}

          {translation.permission && translation.permission.system && (
            <React.Fragment>
              <h6 className="panel__subtitle">
                {translation.permission.system.heading}
              </h6>

              <p>{translation.permission.system.description}</p>

              {translation.permission.system.extraLink && (
                <div>
                  <span>{translation.permission.system.extraLink.prefix}</span>
                  
                  <a
                    href={translation.permission.system.extraLink.link}
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    {translation.permission.system.extraLink.linkText}
                  </a>

                  <span>{translation.permission.system.extraLink.suffix}</span>
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }
    if (selectedMic && success) {
      return (
        <div className="row">
          <div style={{ marginRight: "10px" }} />
          <div className={""}>
            <p className={"text__success text__bold"}>{translation.success}</p>
          </div>
        </div>
      );
    }
  };

  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <div className="panel panel--md panel--solo">
        <h1 className="panel__title" id="page-title">
          {translation.heading}
        </h1>
        <p>{translation.body}</p>
        <div className="microphone">
          <audio className="microphone__stream" muted={true} />
          <canvas id="meter" className="microphone__visualizer" />
        </div>
        <br />
        <h6>{translation.attachedMics}</h6>
        <br />
        <Select
          isDisabled={success}
          onChange={({ value }) => {
            setSelectedMic(value);
          }}
          defaultValue={{ value: "none", label: "None" }}
          options={[
            { value: "none", label: "None" },
            ...mics.map((mic) => {
              return { value: mic.deviceId, label: mic.label };
            }),
          ]}
        />
        <div style={{ marginTop: "30px" }}>{renderStatus()}</div>

        {success && (
          <div className="panel__action">
            <button
              className="btn btn--icon-right"
              onClick={async () => {
                window.cancelAnimationFrame(rafID);
                await audioContext.close();
                onSuccess();
              }}
            >
              {translation.next}
              <icons.chevronRight />
            </button>
          </div>
        )}
      </div>
    </main>
  );
};

export default flowCheck("MicrophoneCheck")(
  translate("MicrophoneCheck")(MicrophoneCheck)
);
