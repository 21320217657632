import logger from "./logger";
/**
 * Get the audio and video input devices
 * @returns {Promise<unknown>}
 */
export function getVideoAudioDevicesAttached() {
  return new Promise((resolve, reject) => {
    window.navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const webcams = [];
        const microphones = [];
        devices.forEach((device, index) => {
          if (device.kind === "videoinput") {
            if (device.deviceId !== "default") {
              webcams.push({
                deviceId: device.deviceId,
                label: device.label,
              });
            }
          } else if (device.kind === "audioinput") {
            if (device.deviceId !== "default") {
              microphones.push({
                deviceId: device.deviceId,
                label: device.label,
              });
            }
          }
        });
        resolve({ microphones, webcams });
      })
      .catch((error) => {
        logger.error(error);
        reject(error);
      });
  });
}

/**
 * Get the list of webcams attached
 * @returns {Promise<[]>}
 */
export async function getWebcamsAttached() {
  try {
    const devices = await getVideoAudioDevicesAttached();
    logger.log("getWebcamsAttached devices", devices);
    return devices.webcams;
  } catch (e) {
    throw e;
  }
}

/**
 * Get the list of microphones attached
 * @returns {Promise<[]>}
 */
export async function getMicrophonesAttached() {
  try {
    const devices = await getVideoAudioDevicesAttached();
    logger.log("getMicrophonesAttached devices", devices);
    return devices.microphones;
  } catch (e) {
    logger.log("getMicrophonesAttached error", e);
    throw e;
  }
}

/**
 * Prompt user to accept the video permissions
 * @returns {Promise<unknown>}
 */
export function requestVideoPermission() {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream) => {
        resolve();
        logger.log("stream", stream);
      })
      .catch((err) => {
        reject();
        logger.log("u got an error:" + err);
      });
  });
}

/**
 * Prompt the user to accept the audio permissions
 * @returns {Promise<unknown>}
 */
export async function requestAudioPermission() {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((stream) => {
        resolve(stream);
      })
      .catch((err) => {
        reject();
        logger.log("u got an error:" + err);
      });
  });
}

/**
 * Get an audio stream for a specific device
 * @returns {Promise<unknown>}
 */
export async function getAudioStreamFromDevice(deviceId) {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ audio: { deviceId } })
      .then((stream) => {
        resolve(stream);
      })
      .catch((err) => {
        reject(err);
        logger.log("u got an error:" + err);
      });
  });
}

/**
 * Get an video stream for a specific device
 * @returns {Promise<unknown>}
 */
export async function getVideoStreamFromDevice(deviceId) {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ video: { deviceId } })
      .then((stream) => {
        resolve(stream);
      })
      .catch((err) => {
        reject(err);
        logger.log("u got an error:" + err);
      });
  });
}

/**
 * Given a certain device test to see if get input audio into that device
 * @param stream
 * @returns {Promise<unknown>}
 */
export function checkAudioStreamForData(stream) {
  const options = { mimeType: "audio/webm" };
  return new Promise((resolve, reject) => {
    const mediaRecorder = new MediaRecorder(stream, options);
    mediaRecorder.addEventListener("dataavailable", (e) => {
      if (e.data.size > 0) {
        resolve(mediaRecorder);
      }
    });
    mediaRecorder.addEventListener("error", (e) => {
      reject(mediaRecorder);
    });
    mediaRecorder.start(1000);
  });
}

/**
 * Given a certain device test to see if get input video into that device
 * @param stream
 * @returns {Promise<unknown>}
 */
export function checkVideoStreamForData(stream) {
  const options = { mimeType: "video/webm" };
  return new Promise((resolve, reject) => {
    const mediaRecorder = new MediaRecorder(stream, options);
    mediaRecorder.addEventListener("dataavailable", (e) => {
      if (e.data.size > 0) {
        resolve(mediaRecorder);
      }
    });
    mediaRecorder.addEventListener("error", (e) => {
      reject(mediaRecorder);
    });
    mediaRecorder.start(1000);
  });
}
/**
 * Request the screen capture
 * @param displayMediaOptions
 * @returns {Promise<*>}
 */
export async function requestScreenCapture(
  displayMediaOptions = {
    video: true,
    audio: false,
  }
) {
  let captureStream = null;

  try {
    captureStream = await navigator.mediaDevices.getDisplayMedia(
      displayMediaOptions
    );
  } catch (e) {
    throw e;
  }
  return captureStream;
}

/**
 *
  Usage:
  audioNode = createAudioMeter(audioContext,clipLevel,averaging,clipLag);
  audioContext: the AudioContext you're using.
  clipLevel: the level (0 to 1) that you would consider "clipping".
     Defaults to 0.98.
  averaging: how "smoothed" you would like the meter to be over time.
     Should be between 0 and less than 1.  Defaults to 0.95.
  clipLag: how long you would like the "clipping" indicator to show
     after clipping has occured, in milliseconds.  Defaults to 750ms.
  Access the clipping through node.checkClipping(); use node.shutdown to get rid of it.
*/
export function createAudioMeter(audioContext, clipLevel, averaging, clipLag) {
  const processor = audioContext.createScriptProcessor(512);
  processor.onaudioprocess = volumeAudioProcess;
  processor.clipping = false;
  processor.lastClip = 0;
  processor.volume = 0;
  processor.clipLevel = clipLevel || 0.98;
  processor.averaging = averaging || 0.95;
  processor.clipLag = clipLag || 750;

  // this will have no effect, since we don't copy the input to the output,
  // but works around a current Chrome bug.
  processor.connect(audioContext.destination);

  processor.checkClipping = function () {
    if (!this.clipping) return false;
    if (this.lastClip + this.clipLag < window.performance.now())
      this.clipping = false;
    return this.clipping;
  };

  processor.shutdown = function () {
    this.disconnect();
    this.onaudioprocess = null;
  };

  return processor;
}

function volumeAudioProcess(event) {
  let buf = event.inputBuffer.getChannelData(0);
  let bufLength = buf.length;
  let sum = 0;
  let x;

  // Do a root-mean-square on the samples: sum up the squares...
  for (let i = 0; i < bufLength; i++) {
    x = buf[i];
    if (Math.abs(x) >= this.clipLevel) {
      this.clipping = true;
      this.lastClip = window.performance.now();
    }
    sum += x * x;
  }

  // ... then take the square root of the sum.
  let rms = Math.sqrt(sum / bufLength);

  // Now smooth this out with the averaging factor applied
  // to the previous sample - take the max here because we
  // want "fast attack, slow release."
  this.volume = Math.max(rms, this.volume * this.averaging);
}

export function getPermissionStateByName(name) {
  return new Promise((resolve, reject) => {
    if (!window.navigator.permissions || !window.navigator.permissions.query) {
      return reject("No permissions api in the window.navigator");
    }
    window.navigator.permissions
      .query({ name })
      .then(function (result) {
        return resolve(result.state);
      })
      .catch(reject);
  });
}

export function isPermissionGrantedByName(name) {
  return new Promise((resolve, reject) => {
    getPermissionStateByName(name)
      .then((state) => {
        console.log("Permission name and state", name, state);
        if (state === "granted") {
          resolve();
        } else {
          reject(`Permission ${name} is ${state}`);
        }
      })
      .catch(reject);
  });
}

export function isCameraPermissionGranted() {
  return isPermissionGrantedByName("camera");
}

export function isMicrophonePermissionGranted() {
  return isPermissionGrantedByName("microphone");
}

export function isScreenSharePermissionGranted() {
  return isPermissionGrantedByName("display-capture");
}
