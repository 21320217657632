/**
 * Renders a toast component
 * 
 * @property {string} content
 * @property {function} [onDismiss]
 * @property {integer} [timeout]
 * @property {string} [type]
 */

import React from 'react';

// Icons
import * as icons from '../ui/Icons';

const Toast = class Toast extends React.Component {
  constructor() {
    super();

    // Set the initial state
    this.state = {
      exiting: false
    };

    // Create refs
    this.toast = React.createRef();
  }

  componentDidMount() {
    this.startTimeout();

    this.toast?.current?.addEventListener('mouseenter', this.cancelTimeout, false);
    this.toast?.current?.addEventListener('mouseleave', this.startTimeout, false);
  }

  componentWillUnmount() {
    this.toast?.current?.removeEventListener('mouseenter', this.cancelTimeout, false);
    this.toast?.current?.removeEventListener('mouseleave', this.startTimeout, false);
  }

  /**
   * Remove the toast message after the set amount of time.
   * 
   * @function startTimeout
   */
   startTimeout = () => {
    const { timeout } = this.props;

    if(!timeout) {
      return;
    }

    this.toastTimeout = setTimeout(() => {
      this.handleDismiss();
    }, timeout);
  }

  /**
   * Cancel the toast message timeout.
   * 
   * @function cancelTimeout
   */
  cancelTimeout = () => {
    clearTimeout(this.toastTimeout);
  }

  /**
  * Dismiss the toast message
  * 
  * @function handleDismiss
  */
  handleDismiss = () => {
    const { onDismiss, store } = this.props;
    const { AppStore } = store;

    this.setState({
      exiting: true
    });

    clearTimeout(this.toastTimeout);

    // Wait for the animation to finish
    // and then remove the toast.
    setTimeout(() => {
      AppStore.closeToast();

      if(onDismiss) {
        onDismiss();
      }
    }, 300);
  }

  /**
   * Render the appropriate icon
   * 
   * @function renderIcon
   * @param {string} type
   * @returns {icon}
   */
  renderIcon = type => {
    let icon;

    switch (type) {
      case 'positive':
        icon = <icons.checkCircle />;
        break;

      case 'warn':
        icon = <icons.warningCircle />;
        break;

      case 'negative':
        icon = <icons.minusCircle />;
        break;

      default:
        icon = <icons.infoCircle />;
    }

    return icon;
  }

  render() {
    const { content, type } = this.props;
    const { exiting } = this.state;

    return (
      <aside
        className={`toast${
          type ? ` toast--${type}` : ''
        }${
          exiting ? ' toast--exiting' : ''
        }`}
      >
        <i className="toast__icon">
          {this.renderIcon(type)}
        </i>

        <span className="toast__content">
          {content}
        </span>

        <button
          className="toast__close"
          onClick={this.handleDismiss}
        >
          <icons.times />
          <span className="meta">Dismiss</span>
        </button>
    </aside>
    )
  }
}

export default Toast;