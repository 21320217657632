import { action, observable } from "mobx";
// Axios (used for loading static translation file)
import axios from "axios";
import logger from "../lib/logger";

let obx = observable({
  /*-------------------------------------------
    Loading
  -------------------------------------------*/
  loadingCalls: [],
  loading: false,
  loadingClass: "",

  /**
   * Set the loading state to true and add the provided
   * request to the array of current requests
   *
   * @function startLoading
   * @param {string} request
   */
  startLoading: action((request) => {
    obx.loading = true;
    obx.loadingCalls.push(request);
  }),

  /**
   * Remove the provided request to from the array of requests and
   * set the loading state to false if the request array is empty
   *
   * @function finishLoading
   * @param {string} request - The name of the request being loaded
   */
  finishLoading: action((request) => {
    const requestIndex = obx.loadingCalls.indexOf(request);

    if (requestIndex >= 0) {
      obx.loadingCalls.splice(requestIndex, 1);
    } else {
      obx.loadingCalls.length = 0;
    }

    if (obx.loadingCalls.length === 0 || typeof request === "undefined") {
      obx.loadingClass = "leave";
      obx.loading = false;

      // Wait for loader to fade out before removing
      setTimeout(() => {
        obx.loadingClass = "";
        obx.loadingMessage = null;
      }, 250);
    }
  }),

  /*-------------------------------------------
    Error handling
  -------------------------------------------*/
  /**
   * Determine whether to show a specific or general error message
   * and then trigger the showing of the error
   *
   * @function throwError
   * @param {object} error
   */
  throwError: (error) => {
    obx.finishLoading();

    logger.error(error);

    if (typeof error.response !== "undefined") {
      // API errors will return an error object with a response
      // which means we have a message for them
      obx.showErrorMsg(error.response.data);
    } else {
      // If there’s no response with the error, it’s a server
      // connection error (i.e. axios can’t reach the URL)
      obx.showErrorMsg();
    }
  },

  /**
   * Show a dialog with the appropriate error message
   *
   * @function showErrorMsg
   * @param {object} data
   */
  showErrorMsg: (data) => {
    const dialogContent = {
      heading: obx.translation.Errors.heading,
      button: obx.translation.Errors.button,
    };

    if (!data) {
      // Server error (API call returned no data)
      dialogContent.body = obx.translation.Errors[1301].message;
    } else {
      // API call returned data
      if (typeof obx.translation.Errors[data.code] !== "undefined") {
        // Error translation exists
        dialogContent.body = obx.translation.Errors[data.code].message;
      } else {
        // Error translation does not exist
        logger.warn(
          "Error code not present in translation file. Falling back to API response message"
        );
        dialogContent.body = data.message;
      }
    }

    obx.showDialog(dialogContent);

    // Redirect expired session
    if (data && data.code === "5002") {
      window.location.href = "/#/session-expired";
    }
  },

  /*-------------------------------------------
    Translation
  -------------------------------------------*/
  locale: "en",
  translation: null,
  translationLoaded: false,

  /**
   * Load the appropriate translation file
   *
   * @function getTranslation
   */
  getTranslation: action(() => {
    obx.translationLoaded = true;

    const timestamp = new Date().getTime();

    obx.startLoading("getTranslation");

    axios
      .get(`/json/${obx.locale}.json?t=${timestamp}`)
      .then(
        action((response) => {
          obx.translation = response.data;
          obx.finishLoading("getTranslation");
        })
      )
      .catch((error) => {
        obx.throwError(error);
      });
  }),

  /*-------------------------------------------
    System Check Flow
  -------------------------------------------*/
  currentCheckFlowIndex:
    parseInt(localStorage.getItem("currentFlowIndex")) || 0,

  /**
   * Set next screen in check flow.
   *
   * @function setNextCheckFlowIndex
   */

  setNextCheckFlowIndex: action(() => {
    obx.currentCheckFlowIndex = parseInt(obx.currentCheckFlowIndex) + 1;
    localStorage.setItem("currentFlowIndex", obx.currentCheckFlowIndex);
  }),

  /**
   * Set next screen in check flow.
   *
   * @function setNextCheckFlowIndex
   */

  clearNextCheckFlowIndex: action(() => {
    obx.currentCheckFlowIndex = 0;
    localStorage.setItem("currentFlowIndex", 0);
  }),

  /*-------------------------------------------
    Dialog box
  -------------------------------------------*/
  dialog: false,
  dialogContent: null,
  dialogOnCancel: null,
  dialogOnChange: null,
  dialogOnConfirm: null,
  dialogOnTimeout: null,
  dialogTimeout: null,
  dialogType: null,

  /**
   * Show a pop-up dialog box with the provided content and options.
   *
   * @function showDialog
   * @param {object} content
   * @param {string} [type]
   * @param {integer} [timeout]
   * @param {array} [options]
   * @param {function} [onConfirm]
   * @param {function} [onCancel]
   * @param {function} [onTimeout]
   * @param {function} [onChange]
   */
  showDialog: action(
    (
      content,
      type,
      timeout,
      options,
      onConfirm,
      onCancel,
      onTimeout,
      onChange
    ) => {
      if (content) {
        obx.dialogContent = content;
      }

      if (type) {
        obx.dialogType = type;
      }

      if (timeout) {
        obx.dialogTimeout = timeout;
      }

      if (options) {
        obx.dialogOptions = options;
      }

      if (onConfirm) {
        obx.dialogOnConfirm = onConfirm;
      }

      if (onCancel) {
        obx.dialogOnCancel = onCancel;
      }

      if (onTimeout) {
        obx.dialogOnTimeout = onTimeout;
      }

      if (onChange) {
        obx.dialogOnChange = onChange;
      }

      obx.dialog = true;
    }
  ),

  /**
   * Close the current pop-up dialog box
   *
   * @function closeDialog
   */
  closeDialog: action(() => {
    obx.dialog = false;
    obx.dialogContent = null;
    obx.dialogOnCancel = null;
    obx.dialogOnChange = null;
    obx.dialogOnConfirm = null;
    obx.dialogOnTimeout = null;
    obx.dialogTimeout = null;
    obx.dialogType = null;

    // Focus the main content element
    const main = document.querySelector("#main-content");

    if (main) {
      main.focus();
    }
  }),

  /*-------------------------------------------
    Toast message
  -------------------------------------------*/
  toast: null,
  toastOnDismiss: null,
  toastType: null,
  toastTimeout: 5000,

  /**
   * Show a toast message.
   *
   * @function showToast
   * @param {string} content
   * @param {string} type
   * @param {function} onDismiss
   * @param {integer} timeout
   */
  showToast: action((content, type, onDismiss, timeout = 5000) => {
    obx.toast = content;
    obx.toastType = type;
    obx.toastTimeout = timeout;

    if (onDismiss) {
      obx.toastOnDismiss = onDismiss;
    }
  }),

  /**
   * Closes a toast message.
   *
   * @function closeToast
   * @param {object} content
   */
  closeToast: action(() => {
    obx.toast = null;
    obx.toastOnDismiss = null;
    obx.toastType = null;
    obx.toastTimeout = 5000;
  }),

  /*-------------------------------------------
    Drawer
  -------------------------------------------*/
  drawerContent: null,
  drawerMod: null,
  drawerTitle: null,
  drawerToolbar: null,
  drawerUrl: null,

  /**
   * Close the drawer
   * @param {event} e
   */
  closeDrawer: action(function (e) {
    if (e) {
      e.preventDefault();
    }

    const drawer = document.querySelector(".drawer");

    if (drawer) {
      drawer.className += " drawer--hidden";
    }

    // Wait until animation has completed
    // to clear out drawer
    setTimeout(function () {
      obx.drawerContent = null;
      obx.drawerMod = null;
      obx.drawerTitle = null;
      obx.drawerToolbar = null;
      obx.drawerUrl = null;

      document.body.classList.remove("overlay-open");

      const main = document.querySelector("#main-content");
      const toast = document.querySelector(".toast");

      if (main && !toast) {
        main.focus();
        window.scrollTo(0, 0);
      }
    }, 250);
  }),
});

export default obx;
