import React, { useEffect } from 'react';

// Translation
import translate from '../translate/Translate';

// Components
import flowCheck from '../flowCheck/FlowCheck';

// Icons
import * as icons from '../ui/Icons';

/**
 * Renders the main home view
 * 
 * @function Home
 * @param {object} props
 * @property {Function} onSuccess
 * @returns {Function}
 */
const Home = props => {
  const { onSuccess, store, translation } = props;
  const { AppStore } = store;

  useEffect(() => {
    AppStore.clearNextCheckFlowIndex();
  }, []);

  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <div className="panel panel--md panel--solo">
        <h1 className="panel__title" id="page-title">
          {translation.heading}
        </h1>

        <p>{translation.body_open}</p>

        <h2 className="panel__subtitle">
          {translation.sub_heading}
        </h2>

        <ul>
          {translation.requirements.map(requirement =>
            <li>{requirement}</li>
          )}
        </ul>

        <p>{translation.body_close}</p>

        <div className="panel__action">
          <button
            className="btn btn--icon-right"
            onClick={() => {
              onSuccess();
            }}
          >
            {translation.start}
            <icons.chevronRight />
          </button>
        </div>
      </div>
    </main>
  );
};

export default flowCheck("Home")(translate("Home")(Home));
