import React, { useEffect, useState } from 'react';

// Translation
import translate from '../translate/Translate';

// Helpers
import { INTERNET_SPEED_THRESHOLD } from '../../lib/constants';

// Components
import flowCheck from '../flowCheck/FlowCheck';

// Icons
import * as icons from '../ui/Icons';

/**
 * Renders the speed test view
 * 
 * @function SpeedTestCheck
 * @param {object} props
 * @property {Function} onSuccess
 * @returns {Function}
 */
const SpeedTestCheck = props => {
  // Get values from props
  const { translation, onSuccess } = props;

  // Set up state
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  // Main useEffect loop
  useEffect(() => {
    function callback(event) {
      const { data } = event;
      if (
        data.event !== 'start' &&
        data.download &&
        data.upload &&
        (INTERNET_SPEED_THRESHOLD.upload > data.upload ||
          INTERNET_SPEED_THRESHOLD.download > data.download)
      ) {
        setError(true);
        setSuccess(false);
      } else if (data.event !== 'start' && data.download && data.upload) {
        setError(false);
        setSuccess(true);
      }
    }
    window.addEventListener('message', callback);
  }, []);

  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <div className="panel panel--md panel--solo">
        <h1
          className="panel__title"
          id="page-title"
        >
          {translation.heading}
        </h1>

        <p>{translation.body}</p>

        {error && (
          <p>
            <strong className="fail">
              {translation.fail}
            </strong>
          </p>
        )}

        {success && (
          <p>
            <strong className="success">
              {translation.success}
            </strong>
          </p>
        )}

        <iframe
          frameBorder="0"
          height="500px"
          src="https://smarterservices.speedtestcustom.com"
          width="100%"
        />

        {success && (
          <div className="panel__action">
            <button
              className="btn btn--icon-right"
              onClick={onSuccess}
            >
              {translation.next}
              <icons.chevronRight />
            </button>
          </div>
        )}
      </div>
    </main>
  );
};

export default flowCheck('SpeedTestCheck')(
  translate('SpeedTestCheck')(SpeedTestCheck)
);
