import { config } from "../config";
import { CHROME_BROWSER_STATUS } from "./constants";

/**
 * Get the version of chrome
 * Return false is not on chrome
 * @returns {boolean}
 */
export function getChromeVersion() {
  let isChrome = false;
  for (let i in window.navigator.plugins) {
    if (window.navigator.plugins[i].name === "Chrome PDF Viewer") {
      isChrome = true;
    }
  }
  if (isChrome && navigator && navigator.userAgent && navigator.userAgent) {
    let raw = navigator.userAgent.match(/Chrome\/([0-9]+)\./);
    isChrome = raw ? parseInt(raw[1], 10) : false;
  }
  return isChrome;
}

/**
 * Check the status of the chrome browser running
 * @returns {string}
 */
export function chromeBrowserCheck() {
  const version = getChromeVersion();
  if (!version) {
    return CHROME_BROWSER_STATUS.installChrome;
  } else if (version && version >= config.minChromeVersion) {
    return CHROME_BROWSER_STATUS.acceptableChrome;
  } else {
    return CHROME_BROWSER_STATUS.updateChrome;
  }
}
