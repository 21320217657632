import React, { useEffect, useState } from "react";
import { getCurrentOS } from "../../lib/utils";
import "react-accessible-accordion/dist/fancy-example.css";

// Accordion
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Translation
import translate from "../translate/Translate";
import { OS_USER_AGENT_NAMES } from "../../lib/constants";
import flowCheck from "../flowCheck/FlowCheck";
import {
  isCameraPermissionGranted,
  isMicrophonePermissionGranted,
  isScreenSharePermissionGranted,
} from "../../lib/devices";

const PermissionsCheck = (props) => {
  const { translation, onSuccess } = props;
  const [OS, setOS] = useState("");
  const [loading, setLoading] = useState(true);
  const [cameraGranted, setCameraGranted] = useState(false);
  const [microphoneGranted, setMicrophoneGranted] = useState(false);

  useEffect(() => {
    isCameraPermissionGranted()
      .then(() => {
        console.log("Camera Granted");
        setCameraGranted(true);
        setLoading(false);
      })
      .catch((e) => {
        console.log("Camera Denied");
        console.log(e);
        setLoading(false);
      });
    isMicrophonePermissionGranted()
      .then(() => {
        console.log("Mic Granted");
        setMicrophoneGranted(true);
        setLoading(false);
      })
      .catch((e) => {
        console.log("Mic Denied");
        console.log(e);
        setLoading(false);
      });
    setOS(getCurrentOS());
  }, []);

  const renderDirections = () => {
    let osIcon = "unknown-os.png";
    let directions = translation.unknownOS;
    switch (OS) {
      case OS_USER_AGENT_NAMES.mac:
        osIcon = "mac-os.png";
        directions = translation.macOS;
        break;
      case OS_USER_AGENT_NAMES.windows:
        osIcon = "windows-os.png";
        directions = translation.windows;
        break;
      case OS_USER_AGENT_NAMES.chromebook:
        osIcon = "chrome-os.png";
        directions = translation.chromeOS;
        break;
      case OS_USER_AGENT_NAMES.linux:
        osIcon = "linux-os.png";
        directions = translation.linux;
        break;
    }
    return (
      <div>
        <div className={"text__center"} style={{ marginBottom: "20px" }}>
          <img height={"128px"} src={`/img/permissions/${osIcon}`} />
        </div>
        <p>{directions.body}</p>
        {directions.permissions.map((permission, i) => {
          if (permission.name === "camera" && cameraGranted) {
            return null;
          }
          if (permission.name === "microphone" && microphoneGranted) {
            return null;
          }
          return (
            <Accordion
              allowMultipleExpanded={false}
              allowZeroExpanded={true}
              key={`acc-${i}`}
            >
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {permission.heading}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ol>
                    {permission.steps.map((step, i) => (
                      <li key={`step-${i}`}>
                        <h6>{step.description}</h6>
                        {step.image && (
                          <img
                            height={"300px"}
                            src={`/img/permissions/${step.image}`}
                          />
                        )}
                      </li>
                    ))}
                  </ol>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          );
        })}
      </div>
    );
  };

  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <div className="panel panel--md panel--solo">
        <h1 className="panel__title" id="page-title">
          {translation.heading}
        </h1>
        {renderDirections()}
      </div>
    </main>
  );
};

export default translate("PermissionsCheck")(PermissionsCheck);
