import React, { useEffect, useState } from "react";

// Translation
import translate from "../translate/Translate";

// Components
import flowCheck from "../flowCheck/FlowCheck";
import { requestScreenCapture } from "../../lib/devices";

// Icons
import * as icons from '../ui/Icons';

/**
 * Renders the screen share check view
 * 
 * @function ScreenSharingCheck
 * @param {object} props
 * @property {Function} onSuccess
 * @returns {Function}
 */
const ScreenSharingCheck = props => {
  // Get values from props
  const { translation, onSuccess } = props;

  // Set up state
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  return (
    <main
      aria-labelledby="page-title"
      className="page"
      id="main-content"
      tabIndex="-1"
    >
      <div className="panel panel--md panel--solo">
        <h1 className="panel__title" id="page-title">
          {translation.heading}
        </h1>

        <p>{translation.body}</p>

        <p>
          <img
            alt="Example showing how to start screen sharing"
            src="/img/screen-share.jpg"
          />
        </p>

        {error && (
          <strong className="fail">
            {translation.fail}
          </strong>
        )}

        {success && (
          <strong className="success">
            {translation.success}
          </strong>
        )}

        {!success && (
          <button
            className="btn btn--full"
            onClick={() => {
              requestScreenCapture()
                .then((stream) => {
                  setSuccess(true);
                  setError(false);
                  setTimeout(() => {
                    const tracks = stream.getTracks();
                    tracks.forEach((track) => track.stop());
                  }, 500);
                })
                .catch((e) => {
                  setError(true);
                });
            }}
          >
            {translation.activateShare}
          </button>
        )}

        {!error && success && (
          <div className="panel__action">
            <button
              className="btn btn--icon-right"
              onClick={() => {
                onSuccess();
              }}
            >
              {translation.next}
              <icons.chevronRight />
            </button>
          </div>
        )}
      </div>
    </main>
  );
};

export default flowCheck("ScreenSharingCheck")(
  translate("ScreenSharingCheck")(ScreenSharingCheck)
);
