/**
 * Returns the Flow Check for a given component
 */

import React from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import { getCheckScreenPathByOrder } from "../../lib/utils";
import {
  SYSTEM_CHECK_FLOW_ORDER,
  WINDOW_FOCUS_RELOAD_ACCEPTABLE_ROUTES,
} from "../../lib/constants";
import WindowFocusHandler from "../common/WindowFocusHandler";
import logger from "../../lib/logger";

export default function FlowCheck(key) {
  return (Component) => {
    const CheckComponent = (props) => {
      const history = useHistory();
      const location = useLocation();
      const { store } = props;
      const { AppStore } = store;
      const { setNextCheckFlowIndex, currentCheckFlowIndex } = AppStore;

      return (
        <>
          <WindowFocusHandler
            onFocus={() => {
              const acceptablePath = WINDOW_FOCUS_RELOAD_ACCEPTABLE_ROUTES.find(
                (route) => route === location.pathname
              );
              if (acceptablePath) {
                window.location.reload();
              }
            }}
          />
          <Component
            {...props}
            onSuccess={() => {
              const currentScreenOrder = SYSTEM_CHECK_FLOW_ORDER[key].order;
              let nextCheckPath = getCheckScreenPathByOrder(
                parseInt(currentCheckFlowIndex) + 1
              );
              if (currentCheckFlowIndex - currentScreenOrder >= 1) {
                nextCheckPath = getCheckScreenPathByOrder(
                  parseInt(currentCheckFlowIndex)
                );
              } else {
                setNextCheckFlowIndex();
              }
              logger.debug(
                "FLOW_CHECK: currentCheckFlowIndex",
                currentCheckFlowIndex
              );
              logger.debug("FLOW_CHECK: nextCheckPath", nextCheckPath);
              history.push(nextCheckPath);
            }}
          />
        </>
      );
    };
    return observer(CheckComponent);
  };
}
