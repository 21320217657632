export let routes = {
  index: "/",
  browserCheck: "/browser-check",
  extensionCheck: "/extension-check",
  permissionsCheck: "/permissions-check",
  cameraCheck: "/camera-check",
  microphoneCheck: "/microphone-check",
  displayCheck: "/display-check",
  speedTestCheck: "/speed-test-check",
  screenSharingCheck: "/screen-sharing-check",
  done: "/done",
  expired: "/session-expired",
  unauthorized: "/unauthorized",
};
